import React, { useState } from "react";
import axios from "axios";

import logo from "../../logo.svg";

const RequestDeleteAccount = () => {
  const body = `Send a request to delete your account and personal information
  stored on our system. After submission you will receive an sms
  to verify your request, once the request is verified we will take
  care of deleting your account.`;

  const subject = `Request For Account Deletion`;
  const [loading, setLoading] = useState(false);

  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(true);

  const handleSubmit = async (e) => {
    try {
      // validations
      e.preventDefault();
      if (!validatePhone(phone)) {
        setValidPhone(false);
        return;
      }

      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user_app/v1/users/request_delete_account`,
        {
          phone_number: phone,
        }
      );

      setLoading(false);

      alert(response?.data?.message);
    } catch (error) {
      setLoading(false);
      console.error(error);
      alert(error?.response?.data?.message);
    }
  };

  const validatePhone = (phone) => {
    const startsWithZeroPattern = /^(010|011|012|015)\d{8}$/;
    const startsWithNonZeroPattern = /^(10|11|12|15)\d{8}$/;
    return (
      startsWithZeroPattern.test(phone) || startsWithNonZeroPattern.test(phone)
    );
  };

  return (
    <div className="delete-account-container">
      <div
        style={{
          height: "50vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "200px",
        }}
      >
        <img src={logo} alt="Project Logo" className="logo" />
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <div className="request-delete-account-form">
          <h2>{subject}</h2>
          <p>{body}</p>
          {loading ? (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          ) : (
            <form onSubmit={handleSubmit} autoComplete="off">
              <label htmlFor="phone">Please enter your phone number:</label>
              <div
                style={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    fontSize: "17px",
                    backgroundColor: "#041937",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    color: "white",
                    borderRadius: "5px 0 0 5px",
                  }}
                >
                  +20
                </div>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, "");
                    setPhone(numericValue);
                    if (!validatePhone(numericValue)) {
                      setValidPhone(false);
                    } else {
                      setValidPhone(true);
                    }
                  }}
                  style={{
                    paddingLeft: "50px",
                    fontSize: "16px",
                    verticalAlign: "centre",
                  }}
                  required
                />
              </div>
              <p
                className="error"
                style={{ visibility: validPhone ? "hidden" : "visible" }}
              >
                Please enter a valid egyptian phone number
              </p>

              <button type="submit">Submit</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestDeleteAccount;
