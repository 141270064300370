import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import RequestDeleteAccount from "./components/requestDeleteAccount/RequestDeleteAccount";
import DeleteAccount from "./components/deleteAccount/DeleteAccount";
import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/delete-account" />} />

          <Route path="/delete-account" element={<RequestDeleteAccount />} />
          <Route path="/confirm-delete" element={<DeleteAccount />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
