import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import logo from "../../logo.svg";

const DeleteAccount = () => {
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const phone = searchParams.get("phone_number");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // api call
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/user_app/v1/users/confirm_delete_account?phone_number=${phone}&delete_token=${token}`
      );

      setLoading(false);

      alert(response?.data?.message);
    } catch (error) {
      setLoading(false);
      console.error(error);
      alert(error?.response?.data?.message);
    }
  };

  return (
    <div className="delete-account-container">
      <div
        style={{
          height: "50vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "200px",
        }}
      >
        <img src={logo} alt="Project Logo" className="logo" />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <div className="request-delete-account-form">
          <h2>Confirm Your Account Deletion</h2>
          {loading ? (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <p>Press "Confirm" to delete your account</p>
              <button type="submit">Confirm</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
